<template>
  <div>
    <v-alert
      v-model="alert.isActive"
      border="left"
      close-text="Close Alert"
      dark
      dismissible
      :type="alert.type"
    >
      {{ alert.text }}
    </v-alert>
    <v-data-table
      item-key="id"
      :headers="headers"
      :items="meals"
      class="elevation-1"
      :loading="isLoading"
      loading-text="Загрузка... Пожалуйста, подождите"
      :sort-by="['sectionName']"
      @dblclick:row="dblclickRow"
    >
      <template v-slot:item.isActive="{ item }">
        <v-simple-checkbox v-model="item.isActive" disabled></v-simple-checkbox>
      </template>

      <template v-slot:item.arAdditions="{ item }">
        <div class="text-center">
          <span v-for="(ind, key) in item.arAdditions" :key="key">
            <v-chip class="ma-2" v-if="getActiveAdditionList.find((addit) => addit.id === ind)">
              {{ getActiveAdditionList.find((addit) => addit.id === ind).name }}
            </v-chip>
          </span>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Блюда</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> Добавить </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form v-model="valid">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-switch
                          v-model="editedItem.isActive"
                          :label="editedItem.isActive ? `Показать` : `Скрыть`"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="editedItem.sectionId"
                          :items="getActiveSectionList"
                          item-text="name"
                          item-value="id"
                          single-line
                        >
                          <template v-slot:label> Раздел<sup>*</sup> </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="editedItem.name" :rules="rules.name">
                          <template v-slot:label> Название<sup>*</sup> </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          outlined
                          label="Описание"
                          v-model="editedItem.description"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          type="number"
                          v-model="editedItem.price"
                          hide-spin-buttons
                          :rules="rules.price"
                          prefix="₽"
                        >
                          <template v-slot:label> Стоимость<sup>*</sup> </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="editedItem.weight"
                          label="Вес"
                          placeholder="300 г."
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="editedItem.arAdditions"
                          :items="getActiveAdditionList"
                          item-text="name"
                          item-value="id"
                          label="Добавки"
                          single-line
                          multiple
                          chips
                        >
                          <template v-slot:prepend-item>
                            <v-list-item ripple @click="toggle">
                              <v-list-item-action>
                                <v-icon
                                  :color="
                                    editedItem.arAdditions.length > 0 ? 'indigo darken-4' : ''
                                  "
                                >
                                  {{ icon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title> Выбрать всё </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-select>
                      </v-col>
                      <template v-for="(item, ind) of imgFiles.items">
                        <v-col cols="12" sm="6" :key="ind">
                          <v-card>
                            <v-img
                              v-if="editedItem[`${ind}ImgFileName`]"
                              :src="apiUrl + 'meals/image/' + editedItem[`${ind}ImgFileName`]"
                              max-height="100"
                              contain
                            >
                            </v-img>
                            <v-card-title> {{ item.title }} </v-card-title>
                            <v-card-subtitle> {{ item.subtitle }} <sup>*</sup></v-card-subtitle>
                            <v-card-actions>
                              <v-file-input
                                v-model="editedItem[`${ind}ImgFileValue`]"
                                :loading="loadingImage"
                                truncate-length="14"
                                :clearable="imgFiles.clearable"
                                accept="image/jpeg,image/png,image/jpg"
                                @change="uploadImage($event, ind)"
                                :label="item.label"
                                v-if="item.isShow"
                              >
                              </v-file-input>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="close"> Закрыть </v-btn>
                <v-btn color="blue darken-1" text @click="save" :disabled="!valid">
                  Сохранить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>{{ editedItem.name }}</v-card-title>
              <v-card-text> Удалить блюдо? </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeDelete"> Закрыть </v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"> Удалить </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:item.isShowOnWeb="{ item }">
        <v-tooltip top v-if="item.isShowOnWeb">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" color="green" dark v-bind="attrs" v-on="on"> mdi-eye </v-icon>
          </template>
          <span>На сайте</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="loadMeals"> Обновить </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Meals",
  data: () => ({
    loadingImage: false,
    isLoading: true,
    headers: [
      { text: "", value: "isShowOnWeb" },
      { text: "Актив", value: "isActive" },
      { text: "Раздел", value: "sectionName" },
      { text: "Название", value: "name" },
      { text: "Описание", value: "description" },
      { text: "Цена, р.", value: "price" },
      { text: "Вес", value: "weight" },
      { text: "Добавки для вкуса", value: "arAdditions" },
      { text: "", value: "actions", sortable: false },
    ],
    meals: [],
    editedIndex: -1,
    dialog: false,
    dialogDelete: false,
    editedItem: {
      id: "",
      isActive: true,
      sectionId: "",
      name: "",
      description: "",
      price: "",
      weight: "",
      arAdditions: [],
      largeImgFileName: undefined,
      smallImgFileName: undefined,
      menuImgFileName: undefined,
      orderImgFileName: undefined,
      largeImgFileValue: undefined,
      smallImgFileValue: undefined,
      menuImgFileValue: undefined,
      orderImgFileValue: undefined,
    },
    defaultItem: {
      id: "",
      isActive: true,
      sectionId: "",
      name: "",
      description: "",
      price: "",
      weight: "",
      arAdditions: [],
      largeImgFileName: undefined,
      smallImgFileName: undefined,
      menuImgFileName: undefined,
      orderImgFileName: undefined,
      largeImgFileValue: undefined,
      smallImgFileValue: undefined,
      menuImgFileValue: undefined,
      orderImgFileValue: undefined,
    },
    imgFiles: {
      items: {
        large: {
          isShow: true,
          label: "Добавить",
          title: "Фото большое",
          subtitle: "W 1024 х H 1366 px",
        },
        menu: {
          isShow: true,
          label: "Добавить",
          title: "Фото для меню",
          subtitle: "W 540 х H 480 px",
        },
        small: {
          isShow: true,
          label: "Добавить",
          title: "Фото для телефона",
          subtitle: "W 750 х H 1624 px",
        },
        order: {
          isShow: true,
          label: "Добавить",
          title: "Фото для корзины",
          subtitle: "W 200 x H 200 px",
        },
      },
      labelTemplate: {
        add: "Добавить",
        change: "Изменить",
      },
      clearable: false,
    },
    alert: {
      isActive: false,
      text: "",
      type: "error",
    },
    rules: {
      name: [
        (v) => !!v || "Необходимо добавить название.",
        (v) => (v && v.length <= 50) || "Не более 50 символов",
      ],
      price: [
        (v) => !!v || "Необходимо добавить стоимость.",
        (v) => {
          const pattern = /^\d+\.?\d*$/;
          return pattern.test(v) || "Стоимость может содержать цифры и разделитель - точку.";
        },
      ],
    },
    valid: true,
  }),
  computed: {
    ...mapState([
      "apiUrl",
      "arMeals",
      "arSections",
      "arAdditions",
      "updateTimeMaxInterval",
      "sectionUpdateTime",
      "additionUpdateTime",
      "alertText",
    ]),
    ...mapGetters(["getActiveSectionList", "getActiveAdditionList"]),
    formTitle() {
      return this.editedIndex === -1 ? "Создать блюдо" : "Редактировать блюдо";
    },
    likesAllAdditions() {
      return this.editedItem.arAdditions.length === this.getActiveAdditionList.length;
    },
    likesSomeAdditions() {
      return this.editedItem.arAdditions.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllAdditions) return "mdi-close-box";
      if (this.likesSomeAdditions) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.alert.isActive = false;
      }
      return val || this.close();
    },
    dialogDelete(val) {
      if (val) {
        this.alert.isActive = false;
      }
      return val || this.closeDelete();
    },
  },
  methods: {
    ...mapActions([
      "getMealList",
      "addMeal",
      "editMeal",
      "deleteMeal",
      "getSectionList",
      "getAdditionList",
    ]),
    showMeals() {
      const arr = Object.keys(this.arMeals).map((value) => {
        const obj = this.arMeals[value];
        obj.id = value;
        obj.sectionName = "";
        obj.isShowOnWeb = false;
        if (obj.sectionId && this.arSections[obj.sectionId]) {
          obj.sectionName = this.arSections[obj.sectionId].name;
          obj.isShowOnWeb = this.arSections[obj.sectionId].isActive && obj.isActive;
        }
        return obj;
      });
      this.meals = arr;
    },
    async loadMeals() {
      this.isLoading = true;
      try {
        await this.getMealList();
      } catch (e) {
        this.alert.text = this.alertText.errLoadData;
        this.alert.type = "error";
        this.alert.isActive = true;
      }
      this.showMeals();
      this.isLoading = false;
    },
    editItem(item) {
      this.editedIndex = this.meals.indexOf(item);
      this.editedItem = { ...item };
      ["large", "small", "menu", "order"].forEach((i) => {
        if (this.editedItem[`${i}ImgFileName`]) {
          this.imgFiles.items[i].label = this.imgFiles.labelTemplate.change;
        } else {
          this.imgFiles.items[i].label = this.imgFiles.labelTemplate.add;
        }
      });
      this.dialog = true;
    },
    dblclickRow(value, value2) {
      this.editItem(value2.item);
    },
    deleteItem(item) {
      this.editedIndex = this.meals.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      try {
        await this.deleteMeal(this.editedItem);
        this.showMeals();
      } catch (error) {
        this.alert.text = this.alertText.errDeleteData;
        this.alert.type = "error";
        this.alert.isActive = true;
      }

      this.closeDelete();
    },
    clearAddForm() {
      this.editedItem = { ...this.defaultItem };
      this.editedIndex = -1;

      Object.keys(this.imgFiles).forEach((ind) => {
        if (this.imgFiles[ind].label) {
          this.imgFiles[ind].label = this.imgFiles.labelTemplate.add;
        }
        if (this.imgFiles[ind].isShow) {
          this.imgFiles[ind].isShow = true;
        }
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearAddForm();
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.clearAddForm();
      });
    },
    async save() {
      this.editedItem.arAdditions = this.editedItem.arAdditions.map((addit) => +addit);
      this.editedItem.sectionId = +this.editedItem.sectionId;
      try {
        if (this.editedIndex > -1) {
          await this.editMeal(this.editedItem);
        } else {
          await this.addMeal(this.editedItem);
        }
        this.showMeals();
      } catch (error) {
        this.alert.text = this.alertText.errAddData;
        this.alert.type = "error";
        this.alert.isActive = true;
      }

      this.close();
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllAdditions) {
          this.editedItem.arAdditions = [];
        } else {
          this.editedItem.arAdditions = this.getActiveAdditionList.map((el) => el.id).slice();
        }
      });
    },
    uploadImage(event, ind) {
      if (event) {
        this.editedItem[`${ind}ImgFileName`] = undefined;
        this.imgFiles.items[ind].label = this.imgFiles.labelTemplate.change;
      }
    },
  },
  async mounted() {
    this.clearAddForm();

    const nowTime = new Date().getTime();
    const shouldUpdate =
      nowTime - this.sectionUpdateTime > this.updateTimeMaxInterval ||
      nowTime - this.additionUpdateTime > this.updateTimeMaxInterval;
    if (
      Object.keys(this.getActiveSectionList).length === 0 ||
      Object.keys(this.getActiveAdditionList).length === 0 ||
      shouldUpdate
    ) {
      try {
        await this.getSectionList();
        await this.getAdditionList();
      } catch (error) {
        this.alert.text = this.alertText.errLoadData;
        this.alert.type = "error";
        this.alert.isActive = true;
      }
    }
    try {
      await this.loadMeals();
    } catch (error) {
      this.alert.text = this.alertText.errLoadData;
      this.alert.type = "error";
      this.alert.isActive = true;
    }
  },
};
</script>
